<template>
	<div class="chat-alerts-parent">
		<transition
			enter-active-class="animated slideInDown faster"
			leave-active-class="animated slideOutUp faster"
			mode="out-in"
		>
			<div
				v-if="showChatAlert && latestYouTubeRequest"
				:key="'chatAlert-'+latestYouTubeRequest.id"
				class="chat-alert text-center"
			>
				<i class="fa fa-youtube-play mr-1"></i>
				NEW REQUEST <em class="mx-1">from</em>
				<span class="text-author">
					{{ latestYouTubeRequest.author }}
				</span>

			</div>
		</transition>

		<transition-group leave-active-class="animated fadeOut faster">

			<!-- CHAT ALERTS COIN -->
			<ChatAlertsCoin
				v-for="item in requests"
				:key="item.fbId || item.id"
				:dataObject="item"
			/>
		</transition-group>

	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import ChatAlertsCoin from './ChatAlertsCoin.vue';

	export default {
		components: {
			ChatAlertsCoin
		},
		data() {
			return {
				chatAlertTimeout: null,
				chatAlertTimeoutDuration: 1200, // this has to coincide with task duration
				showChatAlert: null
			}
		},
		computed: {
			...mapGetters({
				getActiveTask: 'tasks/getActiveTask',
				getActiveTasks: 'tasks/getActiveTasks'
			}),
			latestYouTubeRequest() {
				return this.getActiveTask('youTubePlaylist', 'Request Video') ||
					   this.getActiveTask('youTubePlaylist', 'Request Playlist');
			},
			requests() {

				const mediaPosterRequests = (!!this.getActiveTask('mediaPoster', 'Request') && this.getActiveTask('mediaPoster', 'Request').data.arg !== '<name>')
												? [this.getActiveTask('mediaPoster', 'Request')] : [];

				const channelSurf = (!!this.getActiveTask('youTubePlaylist', 'Surf'))
									 ? [this.getActiveTask('youTubePlaylist', 'Surf')] : [];

				const playerLock = (!!this.getActiveTask('youTubePlayer', 'Lock'))
									 ? [this.getActiveTask('youTubePlayer', 'Lock')] : [];

				const dashboardTVOff = (!!this.getActiveTask('dashboardTVChannel', 'Off'))
									 ? [this.getActiveTask('dashboardTVChannel', 'Off')] : [];

				const twitchRequest = (!!this.getActiveTask('twitchPlayer', 'Request Media'))
									 ? [this.getActiveTask('twitchPlayer', 'Request Media')] : [];

				return [
							...this.getActiveTasks('youTubePlaylist', 'Request Video'),
							...mediaPosterRequests,
							...channelSurf,
							...playerLock,
							...dashboardTVOff,
							...twitchRequest
					   	];
			}
		},
		watch: {
			latestYouTubeRequest(newVal, oldVal) {
				if (!!newVal && newVal !== oldVal) {
					this.showChatAlert = true;
					clearTimeout(this.chatAlertTimeout);
					this.chatAlertTimeout = setTimeout(() => this.showChatAlert = false, this.chatAlertTimeoutDuration);
				}
			}
		},
		destroy() {
			clearTimeout(this.chatAlertTimeout);
		}
	}
</script>

<style lang="scss" scoped>

	.slideOutUp {
		animation: chatAlertLeave 500ms ease 0s alternate 1 forwards;
		@keyframes chatAlertLeave {
			from { transform: translateY(0); }
			to   { transform: translateY(calc(-100% - #{rem-calc(15)})); } // the top offset
		}
	}

	.chat-alerts-parent {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// border: 1px dashed yellow;
		overflow: hidden;
		pointer-events: none;
		z-index: 2;
	}

	.chat-alert {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: rem-calc(5);
		left: rem-calc(5);
		width: calc(100% - #{rem-calc(10)});
		height: rem-calc(37);
		padding: rem-calc(5 15);
		border-radius: 5px;
		// background: lighten($primary, 10%);
		// background: linear-gradient(to bottom, lighten($primary, 5%) 5%, #a6cd39 100%);
		background: linear-gradient(to top, lighten(#343640, 15%) 0%, lighten(#343640, 30%) 100%);
		font-size: rem-calc(19);
		font-weight: 500;
		font-family: 'Avenir Next Condensed';
		line-height: 12px;
		box-shadow: 0 1px 10px 5px rgba(black, 0.7); // 0.8
		text-shadow: 0 1px 1px rgba(black, 0.6);
		// text-shadow: 0 1px 0px rgba(white, 0.5);
		color: white;
		user-select: none;

		// will-change: transform;
		// transform: transform3d(0, 0, 0);
	}

	.chat-alert .fa {
		color: darken(white, 20%);
	}

	.chat-alert .text-author {
		// font-weight: 400;
		font-style: italic;
		color: $primary;
	}

</style>